import React from 'react';
import {Link}from 'gatsby';
import Page from '../../components/pages/Page';

const i18n = {
  titleId: 'about',
  content: (
    <>
      <p>Hi!</p>
      <p>I'm a web developer living in Antwerp.</p>
      <p>With a <strong>bachelor's degree in SME-management</strong>, I started of my career in procurement. Quickly I realized this was not the profession I could see myself doing my entire life and pursued a career as a Front-End Developer.
        Nowadays I do web developement as a <strong>Consultant at Pàu Antwerp</strong>. I never regretted the choice of making a career change an love doing what I do every single day.</p>

      <p>I strive to continiously improve myself as a web developer and love helping out small to medium sized enterprises building their online presence through side-projects.</p>

      <p>I primarly do front-end work but kan work on the back-end as well, using javascript (Node JS). Love working with ReactJs and also have knowledge of conversion optimization, SEO, performance optimization and (online) marketing.</p>
      If you like to know more, <Link to="/contact">contact me</Link>.

      <p>Cheers!</p>
    </>
  ),
  description: `
    Developer with 12 years of experience.
  `
};

export default (props) => <Page i18n={i18n} {...props} />;
